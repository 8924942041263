import { gsap } from "gsap";
const global = () => {
  const offCanvasMenu = document.querySelector(".off-canvas-content");

  document.getElementById("menu-trigger").addEventListener("click", () => {
    offCanvasMenu.style.transform = "translateY(0%)";
  });

  document.getElementById("off-canvas-close").addEventListener("click", () => {
    offCanvasMenu.style.transform = "translateY(-100%)";
  });

  const footerMailingListWrap = document.querySelector(".mailing-list-form");
  const footerMailingList = document.querySelector(
    ".mailing-list-form .wpcf7-form",
  );

  footerMailingList.addEventListener(
    "wpcf7mailsent",
    () => {
      footerMailingListWrap.style.width = "368px";
      footerMailingListWrap.style.border = "1px solid #fff";
      footerMailingListWrap.style.padding = "8px 18px";
      footerMailingListWrap.style.fontSize = "10px";
      footerMailingListWrap.style.backgroundColor = "#ffffff";
      footerMailingListWrap.style.color = "#000000";
      footerMailingListWrap.style.letterSpacing = "0.3em";
      footerMailingListWrap.innerHTML = "<div>THANK YOU</div>";
    },
    false,
  );

  jQuery(function ($) {
    $(".menu-item-has-children").append(
      "<div class='dropdown-wrap'><div class='dropdown'></div></div>",
    );

    /// Desctop Menu
    const subMenuDesktop = document.querySelector(
      "#_nav_menu-251-28 .menu-item-has-children .sub-menu",
    );
    subMenuDesktop.setAttribute("isOpen", "false");
    document
      .querySelector("#_nav_menu-251-28 .menu-item-has-children .dropdown-wrap")
      .addEventListener("click", (e) => {
        // e.preventDefault();
        console.log(subMenuDesktop.getAttribute("isOpen"));
        if (subMenuDesktop.getAttribute("isOpen") === "true") {
          subMenuDesktop.setAttribute("isOpen", "false");
          gsap.to(subMenuDesktop, {
            height: "0px",
            duration: 1,
          });
        } else {
          subMenuDesktop.setAttribute("isOpen", "true");
          gsap.to(subMenuDesktop, {
            height: "auto",
            duration: 1,
          });
        }
      });

    /// Mobile Menu
    const subMenuMobile = document.querySelector(
      "#_nav_menu-272-85 .menu-item-has-children .sub-menu",
    );
    document
      .querySelector("#_nav_menu-272-85 .menu-item-has-children .dropdown-wrap")
      .addEventListener("click", (e) => {
        // e.preventDefault();
        console.log(subMenuMobile.getAttribute("isOpen"));
        if (subMenuMobile.getAttribute("isOpen") === "true") {
          subMenuMobile.setAttribute("isOpen", "false");
          gsap.to(subMenuMobile, {
            height: "0px",
            duration: 1,
          });
        } else {
          subMenuMobile.setAttribute("isOpen", "true");
          gsap.to(subMenuMobile, {
            height: "auto",
            duration: 1,
          });
        }
      });
  });

  const mailchimpCheckboxs = document.querySelectorAll(
    ".mc4wp-checkbox.mc4wp-checkbox-contact-form-7 input",
  );
  mailchimpCheckboxs.forEach((mailchimpCheckbox) => {
    mailchimpCheckbox.checked = true;
  });

  // jQuery(function ($) {
  //   $(".menu-item-has-children").append(
  //     "<div class='dropdown-wrap'><div class='dropdown'></div></div>",
  //   );
  //   const subMenuDesktop = document.querySelector(
  //     "#_nav_menu-251-28 .menu-item-has-children .sub-menu",
  //   );
  //   subMenuDesktop.setAttribute("isOpen", "false");
  //   document
  //     .querySelector("#_nav_menu-251-28 .menu-item-has-children .dropdown-wrap")
  //     .addEventListener("click", (e) => {
  //       // e.preventDefault();
  //       console.log(subMenuDesktop.getAttribute("isOpen"));
  //       if (subMenuDesktop.getAttribute("isOpen") === "true") {
  //         subMenuDesktop.setAttribute("isOpen", "false");
  //         gsap.to(subMenuDesktop, {
  //           height: "0px",
  //           duration: 1,
  //         });
  //       } else {
  //         subMenuDesktop.setAttribute("isOpen", "true");
  //         gsap.to(subMenuDesktop, {
  //           height: "auto",
  //           duration: 1,
  //         });
  //       }
  //     });
  // });

  // const subMenuMobile = document.querySelector(
  //   "#_nav_menu-272-85 .menu-item-has-children .sub-menu",
  // );
  // document
  //   .querySelector("#_nav_menu-272-85 .menu-item-has-children a")
  //   .addEventListener("click", (e) => {
  //     e.preventDefault();
  //     console.log(subMenuMobile.getAttribute("isOpen"));
  //     if (subMenuMobile.getAttribute("isOpen") === "true") {
  //       window.location = e.target.href;
  //     } else {
  //       e.remove;
  //       subMenuMobile.setAttribute("isOpen", "true");
  //       gsap.to(subMenuMobile, {
  //         height: "auto",
  //         duration: 1,
  //       });
  //     }
  //   });
};
global();
export default global;
